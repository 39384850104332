import soda_granul from './Assets/каустическая_сода_гранул.jpg'
import soda_chesh from './Assets/каустическая_сода_чешур.jpg'
import dietanolamid from './Assets/диэтаноламид.jpg'
import tripolifosfat from './Assets/триполифосфат_натрия.jpg'
import benzoat_natriya from './Assets/бензоат_натрия.jpg'
import etilen from './Assets/этиленгликоль.jpg'
import propilenglikol_kan_20 from './Assets/пропиленгликоль_20кг.jpg'
import propilenglikol_kan_10 from './Assets/пропиленгликоль_10кг.jpg'
import propilenglikol_boch from './Assets/пропиленгликоль_бочки.jpg'
import sulfkis from './Assets/cульфаминовая_кислота.png'
import alkilbenz from './Assets/алкилбензолсульфокислота.jpg'
import betain from './Assets/бетаин_кокоамидоприл.jpg'
import acesylfam from './Assets/ацесульфам_калия.jpg'
import polyacesweet from './Assets/polyacesweet.jpg'
import aces from './Assets/aces.jpg'

export const products = [
  {
    id: 1,
    img: soda_chesh,
    name: 'Каустическая сода, чешуированная',
    desc: `Применяется для бытовых нужд, а также в разных отраслях промышленности. 
        В быту используется для растворения жира, волос, остатков пищи, отстирывания пятен мазута, дезинфекции.`,
    manufacturer: "Иран, CHLORAN",
    packing: "Мешки 25кг (новые)",
    price: 'от 77 руб/кг (с НДС)',
  },
  // {
  //   id: 2,
  //   img: soda_granul,
  //   name: 'Каустическая сода, гранулированная',
  //   desc: `Применяется для бытовых нужд, а также в разных отраслях промышленности. 
  //       В быту используется для растворения жира, волос, остатков пищи, отстирывания пятен мазута, дезинфекции.`,
  //   manufacturer: "АО «Каустик», Волгоград",
  //   packing: "Мешки 25кг (новые)",
  //   price: 'от 84 руб/кг (с НДС)',
  // },
  {
    id: 2,
    img: dietanolamid,
    name: 'Диэтаноламид (кокодиэтаноламид кокосового масла)',
    desc: `Широко применяется в производстве средств личной гигиены, 
        бытовых и промышленных моющих средств, косметических препаратов. 
        Выполняет роль стабилизатора пены, растворителя и закрепителя отдушки.`,
    manufacturer: "Малайзия",
    packing: "Бочки 200кг",
    price: 'от 205 руб/кг (с НДС)',
  },
  {
    id: 3,
    img: tripolifosfat,
    name: 'Триполифосфат натрия ,технический',
    desc: `Широко применяется в качестве компонента для производства синтетических моющих средств,
        водоочистки, а также в керамической, лакокрасочной, и других отраслях промышленности.`,
    manufacturer: "АО «Апатит»",
    packing: "Мешки 50кг",
    price: 'от 110 руб/кг (с НДС)',
  },
  {
    id: 4,
    img: benzoat_natriya,
    name: 'Бензоат натрия (Е-211)',
    desc: `Консервант. Отрасли применения: Мясная продукция, производство сыров, напитки безалкогольные, 
        косметическая продукция, масложировая продукция, молочная продукция, фармацевтическая продукция, 
        производство мясных, рыбных и овощных консервов.`,
    manufacturer: "Китай",
    packing: "Мешки 25кг",
    price: 'от 210 руб/кг (с НДС)',
  },
  {
    id: 5,
    img: etilen,
    name: 'Этиленгликоль (моноэтиленгликоль), высщий сорт',
    desc: `Этиленгликоль используется в фармацевтической, косметической, парфюмерной и табачной промышленности, 
        в текстильном, кожевенном деле; в качестве растворителя красок, 
        сырья для производства полиэтилентерефталата, глифталевых смол, чернил; для получения динитрогликоля. 
        Наиболее важным свойством этиленгликоля является его способность понижать температуру замерзания воды, 
        чем и обусловлено применение 50 - 60 % водных растворов его в качестве антифриза.`,
    manufacturer: "АО «Сибур-Нефтехим»",
    packing: "Бочки 220кг (новые)",
    price: 'от 90 руб/кг (с НДС)',
  },
  {
    id: 6,
    img: propilenglikol_kan_20,
    name: 'Пропиленгликоль технический (канистра 20кг)',
    desc: `Пропиленгликоль технический используют для приготовления тормозных жидкостей, антифризов, теплоносителей для систем отопления.
        Продукт применяется в качестве пластификатора при производстве целлофановых и поливинилхлоридных пленок, 
        в производстве ненасыщенных полиэфирных смол (для строительной индустрии, автопромышленности), 
        эластичных полиуретанов и алкидных смол.`,
    manufacturer: "Китай",
    packing: "Канистра 20кг",
    price: 'от 3500 руб/шт',
  },
  {
    id: 7,
    img: propilenglikol_kan_10,
    name: 'Пропиленгликоль технический (канистра 10кг)',
    desc: `Пропиленгликоль технический используют для приготовления тормозных жидкостей, антифризов, теплоносителей для систем отопления.
        Продукт применяется в качестве пластификатора при производстве целлофановых и поливинилхлоридных пленок, 
        в производстве ненасыщенных полиэфирных смол (для строительной индустрии, автопромышленности), 
        эластичных полиуретанов и алкидных смол.`,
    manufacturer: "Китай",
    packing: "Канистра 10кг",
    price: 'от 1900 руб/шт',
  },
  {
    id: 8,
    img: propilenglikol_boch,
    name: 'Пропиленгликоль пищевой (бочка 215кг)',
    desc: `Пищевой пропиленгликоль используется в качестве пищевой добавки Е1520, добавляется в
        алкогольные и безалкогольные напитки, энергетики, хлебобулочные изделия и другую выпечку,
        конфеты, жевательную резинку, в сиропы и приправы. Используется пропиленгликоль и в
        пищевых ароматизаторах.`,
    manufacturer: "Китай",
    packing: "Бочки 215кг",
    price: 'от 176 руб/кг (с НДС)',
  },
  // {
  //   id: 10,
  //   img: sulfkis,
  //   name: 'Сульфаминовая кислота',
  //   desc: `Применяется для промывки внутренних поверхностей теплотехнического оборудования на ТЭЦ;
  //       в качестве компонента в производстве чистящих средств и в других отраслях народного хозяйства.`,
  //   manufacturer: "АО «ПИГМЕНТ»",
  //   packing: "Мешки 40кг",
  //   price: 'от 177 руб/кг (с НДС)',
  // },
  {
    id: 9,
    img: alkilbenz,
    name: 'Алкилбензолсульфокислота',
    desc: `АБСК – эффективный пенообразователь, поверхностно-активное вещество, 
    составляющее моющих средств и прекрасное производное для изготовления нейтральных ПАВ.`,
    manufacturer: "Иран",
    packing: "Бочки 216кг",
    price: 'от 170 руб/кг (с НДС)',
  },
  {
    id: 10,
    img: betain,
    name: 'Кокамидопропилбетаин',
    desc: `Увеличивает пенообразование и загущает в сочетании с основным анионным ПАВ и хлоридом натрия. 
    Используется в качестве усилителя пены в шампунях, также используется в косметике в качестве эмульгатора, 
    загустителя, антистатика в кондиционерах для волос, проявляет антисептические свойства. 
    Совместим с другими катионными, анионными и неионогенными поверхностно-активными веществами`,
    manufacturer: "Иран",
    packing: "Бочки 230кг",
    price: '165 руб/кг',
  },
  {
    id: 11,
    img: acesylfam,
    name: 'Ацесульфам калия',
    desc: `Является некалорийным усвояемым подсластителем.
    Обладает способностью усиливать и подчеркивать многие ароматы, что позволяет снижать содержание ароматизаторов`,
    manufacturer: "Китай",
    packing: "25кг",
    price: '890 руб/кг (с НДС)',
  },
  {
    id: 12,
    img: aces,
    name: 'Ацесульфам калия',
    desc: ``,
    manufacturer: "Китай (JINHE SWEET)",
    packing: "25кг",
    price: '910 руб. (с НДС)',
  },
  {
    id: 13,
    img: polyacesweet,
    name: 'Подсластитель. Сахарозаменитель PolyAspaSweet-200',
    desc: `Порошкообразный.
    Коэффициент сладости: 200.
    Состав: подсластители ( Е951 Е950, Е954, Е952).
    Полная замена сахара в напитках. Вкус максимально приближен к вкусу натурального сахара. 
    Для применения в производстве газированных напитков(лимонад, кола, фанта, холодный чай и.т.д.). 
    Растворимость: водорастворимый. 
    Хранить в закрытой упаковке, при t<25°С и влажности воздуха < 75%.
    Декларация о соответствии: N RU Д-RU.РА09.В.64853/24. 
    Протокол исследований(анализа) №ТП/24-00358.`,
    manufacturer: "Китай",
    packing: "20кг",
    price: '1150 руб.',
  },
]
